<template>
  <div class="content_block">
    <div class="top">
      <b>开户信息</b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini"  @click="handleCancel" v-if="!isShowHead">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowHead">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <template v-if="isEditing">
      <el-form :model="form" ref="JZForm" :rules="rules" label-width="100px" label-position="right">
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行名称" prop="bankId">
              <BankSelector v-model="form.bankId" :name.sync="form.bankName" placeholder="请选择银行名称" @change="handleChangeBank"></BankSelector>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户行" prop="openingBankId">
              <OpeningBankSelector v-model="form.openingBankId" :name.sync="form.openingBankName" :bankId="form.bankId" placeholder="请选择开户行"></OpeningBankSelector>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行账户" prop="bankAccount">
              <el-input placeholder="请输入银行账户" v-model.trim="form.bankAccount" maxlength="19" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附件信息" prop='tenantFileReqDTOs'>
              <Upload
                v-model="form.tenantFileReqDTOs"
                label="附件信息"
                buttonText="上传"
                type="single"
                :show-file-list="false"
                @success="handleFileLists"
                @remove="handleRemove"
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                :maxSize="10"
                size="origin"
                :limit="10"
              >
              </Upload>
            </el-form-item>
          </el-col>
        <el-col :span="24"  v-if="form.tenantFileReqDTOs&&form.tenantFileReqDTOs.length">
          <el-form-item label="">
            <ul class="imgContainer">
              <li v-for="(img,index) in form.tenantFileReqDTOs" :key="img.url">
                <div class="left">
                  <el-link type="primary" :href="img.url" :underline="false" target="blank">
                    <span class="name" :title='img.name'><img :src="require('@/assets/pdf.svg')" />{{ img.name }}</span>
                  </el-link>
                </div>
                <div class="right">
                  <i class="el-icon-success" style="color:#67C23A;margin-right: 8px;font-size:16px;"></i>
                  <i class="el-icon-delete" style="color:#409EFF;font-size:16px;cursor: pointer;" @click="handleDelete(index)"></i>
                </div>
              </li>
            </ul>
          </el-form-item>
        </el-col>
        </el-row>
      </el-form>
    </template>
    <el-descriptions v-else :colon="false" :column="2" style="margin-top: 10px;">
      <el-descriptions-item label="银行名称" labelClassName="warningLabel" contentClassName="warningContent">{{ form.bankName }}</el-descriptions-item>
      <el-descriptions-item label="开户行" labelClassName="warningLabel" contentClassName="warningContent">{{ form.openingBankName }}</el-descriptions-item>
      <el-descriptions-item label="银行账户" :span="2" labelClassName="warningLabel" contentClassName="warningContent">{{ form.bankAccount }}</el-descriptions-item>
      <el-descriptions-item label="附件信息" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
        <div v-for="img in form.tenantFileReqDTOs" :key="img.url" class="fileContainer">
          <i class="el-icon-document" style="color:#FF5959;margin-right:4px;"></i><el-link type="primary" :href="img.url" :underline="false" target="blank"> {{ img.name }}</el-link>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import OpeningBankSelector from '@/components/openingBankSelector';
import BankSelector from '@/components/bankSelector';
export default {
  name: '',
  components: {
    OpeningBankSelector,
    BankSelector,
  },
  props: {
    certificateLists: {
      type: Array,
      default () {
        return [];
      },
    },
    type: {
      type: String,
      default: '14',
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
    activeName: {
      type: String,
      default: '',
    },
  },
  watch: {
    activeName: {
      handler (val) {
        if (val === 'AccountInformation') {
          let info = this.certificateLists.find(i=>i.type === this.type);
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          this.form = info ? {
            ...info,
            establishDate: info && info.establishDate ? this.$options.filters['date-format'](info.establishDate, 'YYYY-MM-DD') : '',
            dueDate: info && info.dueDate ? this.$options.filters['date-format'](info.dueDate, 'YYYY-MM-DD') : '',
          } : {
            bankId: '',
            bankName: '',
            openingBankName: '',
            openingBankId: '',
            bankAccount: '',
            tenantFileReqDTOs: [],
            type: this.type,
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
    isShowHead: {
      handler (val) {
        if (val) {
          this.isEditing = this.isShowHead;
        }
      },
      immediate: true,
      deep: true,
    },
    certificateLists: {
      handler (val) {
        if (val && val.length) {
          let info = val.find(i=>i.type === this.type);
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          let tenantFileReqDTOs = (info.tenantFileReqDTOs || []).filter(i=>i.type === this.type) || [];
          this.form = info ? {
            ...info,
            tenantFileReqDTOs,
            establishDate: info && info.establishDate ? this.$options.filters['date-format'](info.establishDate, 'YYYY-MM-DD') : '',
            dueDate: info && info.dueDate ? this.$options.filters['date-format'](info.dueDate, 'YYYY-MM-DD') : '',
          } : {
            bankId: '',
            bankName: '',
            openingBankName: '',
            openingBankId: '',
            bankAccount: '',
            tenantFileReqDTOs: [],
            type: this.type,
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    clearValidate () {
      this.$nextTick(()=>{
        this.$refs.JZForm && this.$refs.JZForm.clearValidate();
      });
    },
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      let info = this.certificateLists.find(i=>i.type === this.type);
      info = info ? JSON.parse(JSON.stringify(info)) : null;
      this.form = info ? {
        ...info,
        establishDate: info && info.establishDate ? this.$options.filters['date-format'](info.establishDate, 'YYYY-MM-DD') : '',
        dueDate: info && info.dueDate ? this.$options.filters['date-format'](info.dueDate, 'YYYY-MM-DD') : '',
      } : {
        bankId: '',
        bankName: '',
        openingBankName: '',
        openingBankId: '',
        bankAccount: '',
        tenantFileReqDTOs: [],
        type: this.type,
      };
      this.isEditing = false;
    },
    handleSave () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            type: this.type,
          }));
          this.$emit('updateMaterial', {...this.form, tenantFileReqDTOs}, this.type);
          this.isEditing = false;
        }
      });
    },
    handleUpdate () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            type: this.type,
          }));
          this.$emit('updateMaterial', {...this.form, tenantFileReqDTOs}, this.type);
        }
      });
    },
    handleRemove () {
      console.log(arguments[0]);
    },
    handleFileLists () {
      console.log(arguments[0]);
    },
    handleDelete (index) {
      this.form.tenantFileReqDTOs.splice(index, 1);
    },
    // 银行卡号校验函数
    checkCardNum (rule, value, callback) {
      var reg = /^([1-9]{1})(\d{14}|\d{18})$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error('银行卡号格式不正确'));
      }
    },
    // 手机号码校验函数
    checkPhoneNum (rule, value, callback) {
      var reg = /^1[3456789]\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error('手机号码格式不正确'));
      }
    },
    handleChangeBank () {
      // 清除开户行
      this.form.openingBankId = '';
      this.form.openingBankName = '';
      // 清除银行账户
      this.form.bankAccount = '';
    },
  },
  data () {
    return {
      isEditing: false,
      form: {
        bankId: '',
        bankName: '',
        openingBankName: '',
        openingBankId: '',
        bankAccount: '',
        tenantFileReqDTOs: [],
        type: this.type,
      },
      rules: {
        bankId: [
          { required: true, message: '请选择银行名称', trigger: ['blur', 'change'] },
        ],
        openingBankId: [
          { required: true, message: '请选择开户行', trigger: ['blur', 'change'] },
        ],
        bankAccount: [
          { required: true, message: '请输入银行账户', trigger: ['blur', 'change'] },
          // { validator: this.checkCardNum, trigger: 'blur' },
        ],
        tenantFileReqDTOs: [
          { required: true, message: '请上传附件信息', trigger: ['blur', 'change'] },
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top{
    height: 40px;
    line-height: 40px;
    display: flex;
    .btnContainer{
      flex: 1;
      text-align:right;
    }
  }
}
/deep/ .warningLabel{
  width: 100px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
}
/deep/ .warningContent{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  .fileContainer{
    flex: 1;
  }
}
.imgContainer{
  list-style: none;
  li{
    height: 40px;
    line-height: 20px;
    background: #F5F7FA;
    border-radius: 4px;
    display: flex;
    width:500px;
    padding: 10px 8px;
    margin-bottom: 8px;
    .left{
      flex: 1;
      display: flex;
      .el-link.el-link--primary {
        width:440px;
        display:inline-block;
        height: 20px;
        line-height: 20px;
        span.el-link--inner{
          width: 100%;
          span.name{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            display:inline-block;
          }
        }
      }
    }
    img{
      width:16px;
      height:16px;
      margin-right: 4px;
    }
  }
}
</style>
